import { ReactComponent as XCircle } from "assets/icons/tick-circle-red.svg";
import classNames from "classnames";
import { TherapistCard } from "components/TherapistCard";
import { StepProps } from "pages/FinalCheckout/types";
import { FC } from "react";
import { finalCheckoutService } from "services/finalCheckoutService";
import { useSnapshot } from "valtio";
import styles from "../../FinalCheckout.module.scss";
import localStyles from "./Confirmation.module.scss";

export const Confirmation: FC<StepProps> = ({ handlePrevStep }) => {
  const { therapist, paymentStatus } = useSnapshot(finalCheckoutService);
  return (
    <div className={styles.root}>
      {paymentStatus !== "error" ? (
        <>
          <h1 className={styles.title}>Success!</h1>
          <p className={classNames(styles.text, localStyles.text)}>
            Please have your phone nearby. Your ESA therapist is going to text
            you shortly.
          </p>
          {therapist && <TherapistCard therapist={therapist} />}
        </>
      ) : (
        <>
          <XCircle className={localStyles.xcircle} />
          <h1 className={classNames(styles.title, localStyles.title)}>
            Unsuccessful payment
          </h1>
          <p className={classNames(styles.text, localStyles.error_text)}>
            Please try once again or enter other payment info
          </p>

          <a className={localStyles.link} onClick={handlePrevStep}>
            Try again
          </a>
        </>
      )}
    </div>
  );
};
