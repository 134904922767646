import { ConfirmFormValue } from "components/ConfirmFormValue";
import { Badge } from "components/UIKit/Badge";
import { Button } from "components/UIKit/Button";
import { Checkbox } from "components/UIKit/Checkbox";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { connectPath } from "pages/Connect";
import { useConnect } from "pages/Connect/useConnect";
import { formatPhone } from "utils/formatPhone";
import styles from "./Confirm.module.scss";

export const confirmPath = "/confirm";

export const Confirm = () => {
  const navigate = useNavigateWithQuery();
  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
    onSubmit,
  } = useConnect();

  const values = watch();
  const agreementError =
    errors.agreeDisclosure?.message || errors.agreeReceiveTexts?.message;

  const onEdit = () => navigate(connectPath);

  return (
    <div className={styles.confirmPage}>
      <h1 className={styles.title}>Connect with an ESA Therapist now</h1>
      <p className={styles.subtitle}>
        First, we need your permission for an ESA Therapist to text you and then
        we will ask you a series of clinical questions to help us understand
        your emotional state.
      </p>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputs}>
          <ConfirmFormValue
            label="Name"
            value={`${values.first_name} ${values.last_name}`}
            onClick={onEdit}
          />
          <ConfirmFormValue
            label="Mobile Phone"
            value={formatPhone(values.phone)}
            onClick={onEdit}
          />
          <ConfirmFormValue
            label="Email"
            value={values.email}
            onClick={onEdit}
          />
        </div>

        <Badge
          text="Your number helps us call or text you about your account and for verification purposes"
          className={styles.badge}
        />

        <Checkbox
          label="By utilizing these electronic therapeutic services, I agree that I am soliciting the services of a licensed mental health professional using the telephone or internet to engage with, and visit the therapist. I give my consent to communicate with the therapist by text message. Text messaging is conducted on a wireless carrier's (Verizon, AT&T, Sprint, TMO) network that lacks encryption and where there exists risk of unauthorized disclosure."
          className={styles.checkbox}
          {...register("agreeDisclosure", {
            required: "You must agree to the terms and check both boxes",
          })}
        />
        <Checkbox
          label="I agree to receive texts at my phone number above. Message & data rates may apply. Text STOP to opt-out. Read SMS terms for more info."
          className={styles.checkbox}
          {...register("agreeReceiveTexts", {
            required: "You must agree to the terms and check both boxes",
          })}
        />

        {agreementError && (
          <p className={styles.agreementError}>{agreementError}</p>
        )}
        <Button type="submit" className={styles.submitButton}>
          Connect Now
        </Button>
      </form>
    </div>
  );
};
