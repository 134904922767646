import { HomeLayout } from "components/Layout/HomeLayout";
import { useTitle } from "hooks/useTitle";
import { homePath } from "pages/Home";
import { NotFound } from "pages/NotFound";
import { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { therapistProfileService } from "services/therapistProfileService";
import { therapistName } from "utils/therapistName";
import { useSnapshot } from "valtio";
import { Confirm, confirmPath } from "./Confirm";
import { Start, startPath } from "./Start";
import { Success, successPath } from "./Success";
import styles from "./Connect.module.scss";

export const connectPath = "/connect";

export const Connect = () => {
  const [searchParams] = useSearchParams();
  const { currentProfile } = useSnapshot(therapistProfileService);
  const { setTitle } = useTitle();

  const therapistId = useMemo(
    () => searchParams.get("therapistId"),
    [searchParams]
  );

  useEffect(() => {
    if (therapistId) {
      therapistProfileService.getById(therapistId);
    }
  }, [therapistId]);

  useEffect(() => {
    if (currentProfile) {
      setTitle(
        `TheraPass ESA: Connect with ${therapistName({
          ...currentProfile,
          ...currentProfile.user,
        })}`
      );
    }
  }, [setTitle, currentProfile]);

  if (!therapistId) {
    return <Navigate to={homePath} />;
  }

  return (
    <HomeLayout hasHeader={false}>
      <div className={styles.connectPage}>
        <Routes>
          <Route path={startPath} element={<Start />} />
          <Route path={confirmPath} element={<Confirm />} />
          <Route path={successPath} element={<Success />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </HomeLayout>
  );
};
