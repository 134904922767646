import { OPTIONS_ICONS } from "constants/icons";
import { availabilityOptions } from "constants/options";
import { Availability } from "components/Availability";
import { FormatProfileField } from "components/FormatProfileField";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { stateLicenseService } from "services/stateLicenseService";
import { therapistProfileService } from "services/therapistProfileService";
import { getWebsiteAddress } from "utils/getWebsiteAddress";
import { stateLicenseList } from "utils/stateLicenseList";
import { therapistName } from "utils/therapistName";
import { useSnapshot } from "valtio";
import { VerifiedCircle } from "../../blocks/VerifiedCircle/VerifiedCircle";
import "./PatientsTherapistHeader.scss";

export const PatientsTherapistHeader = () => {
  const { currentProfile } = useSnapshot(therapistProfileService);
  const { data } = useSnapshot(stateLicenseService);

  const professionalSpecialtyNames = useDictionaryItemNames(
    "ProfessionalSpecialties",
    currentProfile?.professional_specialties
  );

  const stateLicenses = stateLicenseList([...data], "license_state");

  if (currentProfile === null) return null;

  return (
    <div className="patients-therapist-header">
      <Availability
        className="patients-therapist-header__available"
        isAvailable={currentProfile?.is_available}
      />
      <div className="patients-therapist-header__title">
        <FormatProfileField shouldLimitLength={false}>
          {therapistName({
            first_name: currentProfile.user.first_name,
            last_name: currentProfile.user.last_name,
          })}
        </FormatProfileField>
        {currentProfile?.primary_credential_is_verified && (
          <VerifiedCircle className="patients-therapist-header__verified" />
        )}
      </div>
      <div className="patients-therapist-header__company">
        {currentProfile.company_name &&
          currentProfile.business_info?.use_company_name_also &&
          (currentProfile.website ? (
            <a href={getWebsiteAddress(currentProfile.website)}>
              {currentProfile.company_name}
            </a>
          ) : (
            <p>{currentProfile.company_name}</p>
          ))}
      </div>
      <p className="patients-therapist-header__subtitle">
        {currentProfile.credentials_choice && (
          <em>
            <FormatProfileField shouldLimitLength={false}>
              {currentProfile.credentials_choice}
            </FormatProfileField>
          </em>
        )}
        {currentProfile?.source?.includes("imported") &&
          professionalSpecialtyNames.length > 0 && (
            <b>
              <FormatProfileField
                fallbackText="No Educational Specialties specified"
                shouldLimitLength={false}
              >
                {professionalSpecialtyNames.filter((item) => item).join(", ")}
              </FormatProfileField>
            </b>
          )}
        {Boolean(stateLicenses?.length) && (
          <b>
            <FormatProfileField shouldLimitLength={false}>
              {`Licensed in: ${stateLicenses}`}
            </FormatProfileField>
          </b>
        )}
      </p>
      <div className="patients-therapist-header__options">
        <p className="patients-therapist-header__options-text">
          Therapy Options:
        </p>
        <ul className="patients-therapist-header__options-container">
          <FormatProfileField fallbackText="No options">
            {OPTIONS_ICONS.filter(
              (option) => currentProfile && currentProfile[option.name]
            ).map(({ name, component: Component }) => (
              <li
                key={name}
                className="patients-therapist-header__options-item"
                aria-label={availabilityOptions[name]}
              >
                <Component />
              </li>
            ))}
          </FormatProfileField>
        </ul>
      </div>
    </div>
  );
};
