/* eslint-disable @typescript-eslint/naming-convention */
import cn from "classnames";
import { Availability } from "components/Availability";
import { Avatar } from "components/Avatar";
import { FormatProfileField } from "components/FormatProfileField";
import { Button } from "components/UIKit/Button";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { useResponsive } from "hooks/useResponsive";
import { connectPath } from "pages/Connect";
import { therapistPath } from "pages/Therapist";
import { FC } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TherapistListItem } from "types/therapist.types";
import { getAvatar } from "utils/getAvatar";
import { stateLicenseList } from "utils/stateLicenseList";
import { therapistName } from "utils/therapistName";
import "./TherapistListingItem.scss";

interface Props {
  className?: string;
  therapist: TherapistListItem;
}

export const TherapistListingItem: FC<Props> = ({ className, therapist }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    first_name,
    last_name,
    photos,
    primary_credential_is_verified,
    in_person_session_availability,
    online_chat_sessions_availability,
    telephone_session_availability,
    video_session_availability,
    id,
    chat_status,
    professional_specialties,
    subscription_plan,
    source,
    verified_state_licenses,
  } = therapist;
  const withAvailableOptions =
    in_person_session_availability ||
    online_chat_sessions_availability ||
    telephone_session_availability ||
    video_session_availability;
  const hasAccessToChat = therapist.subscription_plan.chat_access;
  const withStatus =
    therapist &&
    hasAccessToChat &&
    therapist?.online_chat_sessions_availability;
  const stateLicenses = stateLicenseList([...verified_state_licenses]);

  const professionalSpecialtyName = useDictionaryItemNames(
    "ProfessionalSpecialties",
    professional_specialties
  );

  const isMobile = useResponsive("sm");

  const onMessage = () =>
    navigate({
      pathname: `${connectPath}`,
      search: `?therapistId=${therapist.id}`,
    });

  return (
    <div className={cn("therapist-card", className)}>
      <div className="therapist-card__left">
        <Avatar
          className="therapist-card__left-avatar"
          file={getAvatar(photos)}
          alt={`${first_name} ${last_name}`}
          chat_status={withStatus ? chat_status : undefined}
          onClick={withStatus ? onMessage : undefined}
          id={id}
          planName={subscription_plan.name}
        />
      </div>
      <div className="therapist-card__main">
        <div className="therapist-card__caption">
          <Link
            to={therapistPath.replace(":id", String(id))}
            state={{ prevPage: pathname }}
          >
            <div className="therapist-card__name">
              <FormatProfileField maxLength={50}>
                {therapistName({ first_name, last_name })}
              </FormatProfileField>
              {therapist.company_name && therapist.use_company_name_also && (
                <span className="therapist-card__company">
                  {therapist.company_name}
                </span>
              )}
            </div>
          </Link>
          {primary_credential_is_verified && (
            <div className="therapist-card__verified">
              <i className="therapist-card__verified-icon" />
              Verified
            </div>
          )}
          {hasAccessToChat && (
            <Availability
              className="therapist-card__availability"
              isAvailable={withAvailableOptions}
            />
          )}
        </div>
        {source.includes("imported") && professionalSpecialtyName.length > 0 ? (
          <div className="therapist-card__info">
            <div className="therapist-card__info-tags">
              {professionalSpecialtyName.filter((item) => item).join(", ")}
            </div>
          </div>
        ) : null}
        {verified_state_licenses.length > 0 && (
          <div className="therapist-card__state-list">
            Licensed in: {stateLicenses}
          </div>
        )}
        {withAvailableOptions && (
          <div
            className={cn("therapist-card__options", {
              "therapist-card__options-margin":
                !therapist.company_name &&
                professionalSpecialtyName.length === 0,
            })}
          >
            <span>Therapy Options:</span>
            {in_person_session_availability && (
              <button
                className="therapist-card__options-item person-icon"
                aria-label="In-Person"
              />
            )}
            {telephone_session_availability && (
              <button
                className="therapist-card__options-item phone-icon"
                aria-label="Telephone"
              />
            )}
            {video_session_availability && (
              <button
                className="therapist-card__options-item video-icon"
                aria-label="Video"
              />
            )}
            {online_chat_sessions_availability && (
              <button
                className="therapist-card__options-item chat-icon"
                aria-label="ChatConnect"
              />
            )}
          </div>
        )}
      </div>
      <div className="therapist-card__right">
        <Button
          className={cn("therapist-card__right-msg", "btn-premium")}
          onClick={onMessage}
        >
          <i className="therapist-card__msg-icon" />
          Connect now
        </Button>
        <Button
          className="therapist-card__right-profile"
          isLink
          to={therapistPath.replace(":id", String(id))}
          state={{ prevPage: pathname }}
          color={isMobile ? "turquoise" : "greenlight"}
        >
          View profile
        </Button>
      </div>
    </div>
  );
};
