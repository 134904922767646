import { breakpoints } from "constants/breakpoints";
import { useEffect, useState } from "react";

export const useResponsive = (breakpoint: keyof typeof breakpoints) => {
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const lastBreakpoint = Object.keys(breakpoints).pop();
      setIsMatch(
        breakpoint === lastBreakpoint
          ? window.innerWidth >= breakpoints[breakpoint]
          : window.innerWidth <= breakpoints[breakpoint]
      );
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return isMatch;
};
