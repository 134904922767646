import { listingService } from "services/listingService";
import { ReactComponent as XIcon } from "./icons/x.svg";
import styles from "./ClearFilters.module.scss";

export const ClearFilters = () => {
  return (
    <button
      className={styles.root}
      onClick={() => listingService.clearFilters()}
    >
      <XIcon className={styles.icon} />
      <span className={styles.text}>Clear all</span>
    </button>
  );
};
