import cn from "classnames";
import React, { MouseEventHandler, ReactNode } from "react";
import "./FilterTag.scss";
import { FCWithChildren } from "types/react.types";

type Props = {
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onButtonClick?: MouseEventHandler<HTMLButtonElement>;
  buttonIcon?: ReactNode;
};

export const FilterTag: FCWithChildren<Props> = ({
  className,
  children,
  onClick,
  onButtonClick,
  buttonIcon,
}) => {
  const withButton = !!buttonIcon;
  return (
    <div
      className={cn(
        "filter-tag",
        className,
        !!onClick && "filter-tag--interactive"
      )}
      onClick={onClick}
    >
      <span className="filter-tag__text">{children}</span>
      {withButton && (
        <button
          type="button"
          className="filter-tag__button"
          onClick={(e) => {
            e.stopPropagation();
            onButtonClick?.(e);
          }}
        >
          {buttonIcon}
        </button>
      )}
    </div>
  );
};
