import { states } from "constants/options";
import { Button } from "components/UIKit/Button";
import { Input } from "components/UIKit/Input";
import { AppSelect } from "components/UIKit/Select";
import { REQUIRED_MESSAGE } from "pages/FinalCheckout/constants";
import { StepProps } from "pages/FinalCheckout/types";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { Address, finalCheckoutService } from "services/finalCheckoutService";
import { useSnapshot } from "valtio";

import styles from "../../FinalCheckout.module.scss";

export const ShippingAddress: FC<StepProps> = ({
  handlePrevStep,
  handleNextStep,
}) => {
  const { shippingAddress } = useSnapshot(finalCheckoutService);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Address>({
    defaultValues: shippingAddress || {},
  });

  const onSubmit = (data: Address) => {
    finalCheckoutService.setShippingAddress(data);
    handleNextStep();
  };

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Enter your billing / shipping address</h1>
      <div style={{ maxWidth: 370, width: "100%" }}>
        <div className={styles.inputs}>
          <div className={styles.row}>
            <Input
              placeholder="Street Address"
              {...register("street_address", { required: REQUIRED_MESSAGE })}
              error={errors.street_address?.message}
            />
          </div>

          <div className={styles.row}>
            <Input
              placeholder="City"
              {...register("city", { required: REQUIRED_MESSAGE })}
              error={errors.city?.message}
            />
            <Input
              placeholder="Zip"
              {...register("zip", {
                required: REQUIRED_MESSAGE,
                validate: (value) =>
                  /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) ||
                  "Should be valid zip code",
              })}
              error={errors.zip?.message}
            />
          </div>
          <div className={styles.row}>
            <Controller
              name="state"
              control={control}
              rules={{ required: REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <AppSelect
                  placeholder="State"
                  options={states}
                  {...field}
                  value={states.find(({ value }) => value === field.value)}
                  onChange={(newValue) =>
                    field.onChange(
                      (newValue as { value: string; label: string }).value
                    )
                  }
                  error={fieldState.error?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonsRow}>
        <Button color="white" onClick={handlePrevStep}>
          Back
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Next</Button>
      </div>
    </div>
  );
};
