import { ReactComponent as CloseIcon } from "assets/icons/close-icon.svg";
import cn from "classnames";
import { FilterTag } from "components/UIKit/FilterTag";
import { useResponsive } from "hooks/useResponsive";
import { listingService } from "services/listingService";
import { useSnapshot } from "valtio";
import styles from "../../Listing.module.scss";
import { ClearFilters } from "../ClearFilters";

export const FilterTags = () => {
  const isMobile = useResponsive("sm");
  const { appliedFilters } = useSnapshot(listingService);

  return appliedFilters.length > 0 ? (
    <div className={cn(styles.listingFilterRow, styles.bottom)}>
      <>
        {isMobile && <ClearFilters />}
        {appliedFilters.map((filter) => (
          <FilterTag
            className={styles.listingFilterTag}
            key={filter.value}
            buttonIcon={<CloseIcon />}
            onButtonClick={() =>
              listingService.removeFilter(filter.name, filter.value)
            }
          >
            {filter.label}
          </FilterTag>
        ))}
        {!isMobile && <ClearFilters />}
      </>
    </div>
  ) : null;
};
