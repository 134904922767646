import { ReactComponent as EditIcon } from "assets/icons/pencil.svg";

import { AddButton } from "components/UIKit/AddButton";
import { Button } from "components/UIKit/Button";
import { Card } from "components/UIKit/Card";
import { Input } from "components/UIKit/Input";
import { Radio } from "components/UIKit/Radio";
import { useTitle } from "hooks/useTitle";
import { ChangeEvent, FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  finalCheckoutService,
  PetKind,
  PetInfo,
} from "services/finalCheckoutService";
import { useSnapshot } from "valtio";

import { REQUIRED_MESSAGE } from "../../constants";
import styles from "../../FinalCheckout.module.scss";
import { StepProps } from "../../types";

import { ReactComponent as CatIcon } from "./icons/cat.svg";
import { ReactComponent as DogIcon } from "./icons/dog.svg";

type Errors = {
  kind?: string;
  name?: string;
  weight?: string;
};

type Values = {
  id?: number;
  kind?: PetKind;
  name?: string;
  weight?: number;
};

export const PetsPage: FC<StepProps> = ({ handleNextStep }) => {
  const { pets } = useSnapshot(finalCheckoutService);
  const [mode, setMode] = useState<"list" | "form">(
    pets.length > 0 ? "list" : "form"
  );
  const [currentPetId, setCurrentPetId] = useState<number | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<PetInfo>();

  const onSubmit = (values: PetInfo) => {
    if (currentPetId) {
      finalCheckoutService.editPet(currentPetId, values);
    } else {
      finalCheckoutService.addPet(values);
    }
    setMode("list");
  };

  useTitle("Final Checkout | Pet Info");

  if (mode !== "list")
    return (
      <div className={styles.root}>
        <h1 className={styles.title}>
          {currentPetId === null ? "Enter your pet info" : "Edit your pet info"}
        </h1>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputs}>
            <p>Pet type:</p>

            <Controller
              control={control}
              name="kind"
              rules={{ required: REQUIRED_MESSAGE }}
              render={({ field, fieldState }) => (
                <div className={styles.row}>
                  <Radio
                    name="kind"
                    value="cat"
                    label="Cat"
                    checked={field.value === "cat"}
                    onChange={() => field.onChange("cat")}
                  />
                  <Radio
                    name="kind"
                    value="dog"
                    label="Dog"
                    checked={field.value === "dog"}
                    onChange={() => field.onChange("dog")}
                  />
                  {fieldState.error?.message && (
                    <p>{fieldState.error.message}</p>
                  )}
                </div>
              )}
            />
            <div className={styles.row}>
              <Input
                {...register("name")}
                className={styles.input}
                placeholder="Pet name"
                error={errors.name?.message}
              />
            </div>
            <div className={styles.row}>
              <Input
                {...register("weight")}
                type="number"
                className={styles.input}
                placeholder="Pet weight, lb"
                error={errors.weight?.message}
              />
            </div>

            <div className={styles.row}>
              {currentPetId && (
                <Button
                  color="white"
                  className={styles.input}
                  onClick={() => setMode("list")}
                >
                  Back
                </Button>
              )}
              <Button type="submit" className={styles.input}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    );

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Pet info</h1>
      <div className={styles.content}>
        {pets.length === 0 && <div className={styles.empty}>No pets yet</div>}
        {pets.map((pet) => (
          <Card
            key={pet.id}
            values={[
              { caption: "PET KIND", text: pet.kind },
              { caption: "PET NAME", text: pet.name },
              { caption: "PET WEIGHT", text: `${pet.weight} lb` },
            ]}
            icon={pet.kind === "cat" ? <CatIcon /> : <DogIcon />}
            editIcon={<EditIcon />}
            onRemove={() => finalCheckoutService.removePet(pet.id)}
            onEdit={() => {
              reset(pet);
              setMode("form");
              setCurrentPetId(pet.id);
            }}
          />
        ))}
        <AddButton
          type="button"
          onClick={() => {
            reset({});
            setMode("form");
            setCurrentPetId(null);
          }}
        >
          Add a pet
        </AddButton>
        <div className={styles.buttons}>
          <Button onClick={handleNextStep}>Next</Button>
        </div>
      </div>
    </div>
  );
};
