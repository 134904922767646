import cn from "classnames";
import { useState } from "react";
import {
  Control,
  Controller,
  ValidationRule,
  Message,
  FieldValues,
  UnPackAsyncDefaultValues,
  Path,
} from "react-hook-form";
import PhoneInput from "./react-phone-input-2";
import "./react-phone-input-2/lib/style.css";
import styles from "./InputPhone.module.scss";

interface CountryData {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}

interface Props<T extends FieldValues> {
  className?: string;
  label?: string;
  id?: string;
  control: Control<T>;
  name: Path<UnPackAsyncDefaultValues<T>>;
  rules?: { required: Message | ValidationRule<boolean> };
  errorMessage?: string;
  placeholder?: string;
}

export const InputPhone = <T extends FieldValues>({
  className,
  label,
  id,
  control,
  name,
  rules,
  errorMessage,
  placeholder,
}: Props<T>) => {
  const [dialCode, setDialCode] = useState("");
  const dialCodeRegexp = dialCode ? new RegExp(`^\\+${dialCode}`) : /^/;

  return (
    <div className={cn(styles.field, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          minLength: { value: 11, message: "Minimum 11 characters" },
          maxLength: { value: 15, message: "Maximum 20 characters" },
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <PhoneInput
              containerClass={cn(
                styles.inputContainer,
                error && styles.inputContainerError
              )}
              preferredCountries={["us"]}
              disableCountryCode
              disableCountryGuess
              disableInitialCountryGuess={false}
              inputClass={styles.inputField}
              buttonClass={styles.inputDropdown}
              country={"us"}
              value={value && (value as string).replace(dialCodeRegexp, "")}
              placeholder={placeholder}
              onChange={(newValue: string, newCountry: CountryData) => {
                const { dialCode: newDialCode } = newCountry;
                setDialCode(newDialCode);
                onChange(newValue ? `+${newDialCode}${newValue}` : newValue);
              }}
              onMount={(newValue: string, newCountry: CountryData) => {
                setDialCode(newCountry.dialCode);
              }}
            />
            {error && <p className={styles.errorMessage}>{error.message}</p>}
            {errorMessage && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
          </>
        )}
      />
    </div>
  );
};
