import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as MapMarker } from "assets/icons/map-marker.svg";
import { ReactComponent as Chat } from "assets/icons/option-chat.svg";
import { ReactComponent as Offline } from "assets/icons/option-offline.svg";
import { ReactComponent as Telephone } from "assets/icons/option-telephone.svg";
import { ReactComponent as Video } from "assets/icons/option-video.svg";
import defaultAvatar from "assets/images/avatar.png";
import { useResponsive } from "hooks/useResponsive";
import { upperCase } from "lodash";
import { FC } from "react";
import { TherapistProfile } from "services/therapistProfileService";
import styles from "./TherapistCard.module.scss";

interface Props {
  therapist: TherapistProfile;
}

const THERAPY_OPTIONS_ICONS: { name: keyof TherapistProfile; component: FC }[] =
  [
    {
      name: "in_person_session_availability",
      component: Offline,
    },
    {
      name: "telephone_session_availability",
      component: Telephone,
    },
    {
      name: "video_session_availability",
      component: Video,
    },
    {
      name: "online_chat_sessions_availability",
      component: Chat,
    },
  ];

export const TherapistCard: FC<Props> = ({ therapist }) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    user,
    avatar,
    primary_credential_is_verified,
    license_state,
    is_available,
    title,
    mental_health_role,
  } = therapist;
  /* eslint-enable @typescript-eslint/naming-convention */

  const md = useResponsive("md");
  const xs = useResponsive("xs");

  const cardName = [
    `${user.first_name} ${user.last_name}`,
    upperCase(title),
    upperCase(mental_health_role),
  ]
    .filter((item) => item)
    .join(", ");

  return (
    <div className={styles.therapistCard}>
      <div className={styles.imageContainer}>
        <img
          src={avatar?.file || defaultAvatar}
          alt="Profile image"
          className={styles.avatar}
        />
      </div>
      <div className={styles.info}>
        <h3 className={styles.name}>{cardName}</h3>
        <div className={styles.role}>
          ESA Therapist
          {primary_credential_is_verified && (
            <div className={styles.verifiedBadge}>
              <div className={styles.iconContainer}>
                <CheckIcon />
              </div>
              {!xs && "Verified"}
            </div>
          )}
        </div>
        {license_state && (
          <div className={styles.license}>
            {md ? (
              <MapMarker className={styles.mapMarker} />
            ) : (
              "State License: "
            )}
            {license_state}
          </div>
        )}
        {is_available && (
          <div className={styles.therapyOptions}>
            {md ? "Available therapy options:" : "Therapy Options:"}
            <ul>
              {THERAPY_OPTIONS_ICONS.filter(({ name }) => therapist[name]).map(
                ({ component: Icon, name }) => (
                  <li key={name}>
                    <Icon />
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
