import { Button } from "components/UIKit/Button";
import { Radio } from "components/UIKit/Radio";
import { StepProps } from "pages/FinalCheckout/types";
import { FC } from "react";
import { finalCheckoutService, OfferType } from "services/finalCheckoutService";
import { useSnapshot } from "valtio";

import styles from "../../FinalCheckout.module.scss";
import localStyles from "./Offers.module.scss";

const OFFERS: {
  name: OfferType;
  title: string;
  price: number;
  duration?: string;
}[] = [
  {
    name: "assistance_animal_rights_attorney_protection",
    title: "Assistance Animal Rights Attorney Protection",
    price: 0,
    duration: "60 days",
  },
  {
    name: "landlord_guaranteed_acceptance_insurance",
    title: "Landlord Guaranteed Acceptance Insurance",
    price: 30,
    duration: "60 days",
  },
  {
    name: "assistance_animal_gear",
    title: "Assistance Animal Gear",
    price: 89,
  },
];

export const Offers: FC<StepProps> = ({ handlePrevStep, handleNextStep }) => {
  const { offers } = useSnapshot(finalCheckoutService);
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Choose the offers that you need</h1>
      <div className={localStyles.content}>
        {OFFERS.map((offer) => (
          <div key={offer.name} className={localStyles.card}>
            <div className={localStyles.title}>{offer.title}</div>
            <div className={localStyles.rightColumn}>
              <div className={localStyles.priceWrapper}>
                <span className={localStyles.text}>$</span>
                <span className={localStyles.price}>{offer.price}</span>
                {offer.duration && (
                  <span className={localStyles.text}>/ {offer.duration}</span>
                )}
              </div>
              <div className={localStyles.radioGroup}>
                <Radio
                  label="Yes"
                  name={offer.name}
                  value="yes"
                  checked={offers[offer.name]}
                  onChange={() =>
                    finalCheckoutService.selectOffer(offer.name, true)
                  }
                />
                <Radio
                  label="No"
                  name={offer.name}
                  value="no"
                  checked={!offers[offer.name]}
                  onChange={() =>
                    finalCheckoutService.selectOffer(offer.name, false)
                  }
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.buttonsRow}>
        <Button color="white" onClick={handlePrevStep}>
          Back
        </Button>
        <Button onClick={handleNextStep}>Next</Button>
      </div>
    </div>
  );
};
