import { Back } from "components/Back";
import { ListingLayout } from "components/Layout/ListingLayout";
import { Preloader } from "components/UIKit/Preloader";
import { useResponsive } from "hooks/useResponsive";
import { useTitle } from "hooks/useTitle";
import { ErrorPage } from "pages/Error";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { therapistProfileService } from "services/therapistProfileService";
import { therapistName } from "utils/therapistName";
import { useSnapshot } from "valtio";
import { ModalAvatar } from "./components/ModalAvatar/ModalAvatar";
import { PatientsTherapistHeader } from "./components/PatientsTherapistHeader";
import { PatientsTherapistHeaderMobile } from "./components/PatientsTherapistHeaderMobile";
import { PatientsTherapistTabs } from "./components/PatientsTherapistTabs";
import { ShareThisProfilePopup } from "./components/ShareThisProfilePopup/ShareThisProfilePopup";
import { TherapistLeftSide } from "./components/TherapistLeftSide";
import { TherapistRightSide } from "./components/TherapistRightSide";
import styles from "./Therapist.module.scss";

export const therapistPath = "/therapist/:id";

export const Therapist = () => {
  const [withError, setWithError] = useState(false);
  const navigate = useNavigate();
  const isMobile = useResponsive("layout-flip");
  const { currentProfile, isFetching } = useSnapshot(therapistProfileService);
  const { id } = useParams<{ id: string }>();

  useTitle(
    `${therapistName({
      first_name: currentProfile?.user.first_name,
      last_name: currentProfile?.user.last_name,
    })}`
  );

  useEffect(() => {
    if (id) {
      therapistProfileService
        .getById(id)
        .then(() => setWithError(false))
        .catch(() => {
          setWithError(true);
        });
    }
  }, [id, navigate]);

  if (withError) return <ErrorPage />;

  if (isFetching) return <Preloader />;

  return (
    <ListingLayout>
      <ShareThisProfilePopup />
      <div className={styles.root}>
        <Back className={styles.back} />
        <div className={styles.content}>
          {!isMobile && <TherapistLeftSide />}
          <div className={styles.container}>
            {isMobile ? (
              <PatientsTherapistHeaderMobile />
            ) : (
              <PatientsTherapistHeader />
            )}
            <PatientsTherapistTabs />
          </div>
          {!isMobile && <TherapistRightSide />}
        </div>
      </div>
      <ModalAvatar />
    </ListingLayout>
  );
};
