import MapMarker from "assets/icons/location-marker.svg";
import { FormatProfileField } from "components/FormatProfileField";
import { Map } from "components/Map";
import { therapistProfileService } from "services/therapistProfileService";
import { areaToString } from "utils/areaToString";
import { useSnapshot } from "valtio";
import styles from "./TherapistMapList.module.scss";

const mapStyles = {
  height: 233,
  borderRadius: 20,
  width: "100%",
  marginTop: 25,
  marginBottom: 24,
};

export const TherapistMapList = () => {
  const { currentProfile } = useSnapshot(therapistProfileService);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { city, state, zip_code, street_address, latitude, longitude } =
    currentProfile?.business_info || {};
  const hasAddress = city || state || zip_code || street_address;
  const displayingMap = latitude && longitude && hasAddress;

  if (currentProfile === null) return null;

  return (
    <ul className={styles.root}>
      {!currentProfile?.business_info?.hide_company_address && (
        <li className={styles.item}>
          <p className={styles.title}>Company Address</p>
          {currentProfile?.business_info?.company_name && (
            <p className={styles.text}>
              <FormatProfileField maxLength={60}>
                {currentProfile.business_info.company_name}
              </FormatProfileField>
            </p>
          )}
          {!currentProfile.hide_street_address && (
            <p className={styles.text}>
              {currentProfile?.business_info?.street_address}
            </p>
          )}
          <p className={styles.text}>
            {areaToString(currentProfile.business_info) || "No address"}
          </p>
          {displayingMap && (
            <Map
              location={{
                lat: latitude,
                lng: longitude,
              }}
              markers={[
                {
                  latitude,
                  longitude,
                  id: 0,
                },
              ]}
              styles={mapStyles}
              zoom={15}
              markerOptions={{ url: MapMarker, anchor: null }}
            />
          )}
        </li>
      )}
      {!currentProfile?.hide_personal_address && (
        <li className={styles.item}>
          <p className={styles.title}>Home Address</p>
          {!currentProfile.hide_street_address && (
            <p className={styles.text}>{currentProfile.street_address}</p>
          )}
          <p className={styles.text}>
            {areaToString(currentProfile) || "No address"}
          </p>
        </li>
      )}
    </ul>
  );
};
