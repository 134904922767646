import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { listingService } from "services/listingService";
import { scrollToTop } from "utils/scrollToTop";
import { useSnapshot } from "valtio";

export const useListing = () => {
  const {
    therapists,
    totalCount,
    pageSize,
    currentPage,
    isInitializing,
    isFetching,
    appliedFilters,
    isError,
  } = useSnapshot(listingService);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageCount = Math.ceil(Number(totalCount) / pageSize);
  const state = useLocation().state as null | { [x: string]: any };

  useEffect(() => {
    if (state?.withScroll) {
      scrollToTop();
    }
  }, [state]);

  useEffect(() => {
    listingService.init(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitializing) {
      setSearchParams(new URLSearchParams(listingService.getSearchParams()));
    }
  }, [isInitializing, currentPage, appliedFilters, setSearchParams]);

  return {
    therapists,
    totalCount,
    isFetching: isInitializing,
    isFetchingList: isFetching,
    pageCount,
    currentPage,
    isError,
    clearFilters: () => listingService.clearFilters(),
  };
};
