import { Modal } from "components/Modal";
import { Button } from "components/UIKit/Button";
import { useModal } from "hooks/useModal";
import { FC } from "react";
import styles from "./ContinueSurveyModal.module.scss";

interface Props {
  resolve?: (shouldRestore: boolean) => void;
}

export const ContinueSurveyModal: FC<Props> = ({ resolve }) => {
  const { isOpen, close } = useModal("CONTINUE_SURVEY");

  return (
    <Modal visible={isOpen} className={styles.root}>
      <div className={styles.continueSurveyModal}>
        <h1>It looks like you have already started a survey before...</h1>
        <p>
          Do you want to continue a previously started survey or start from
          scratch?
        </p>

        <div className={styles.buttons}>
          <Button
            color="greenlight"
            onClick={() => {
              close();
              resolve?.(true);
            }}
          >
            Continue previous
          </Button>
          <Button
            onClick={() => {
              close();
              resolve?.(false);
            }}
          >
            Start a new one
          </Button>
        </div>
      </div>
    </Modal>
  );
};
