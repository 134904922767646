import {
  ListOption,
  AVAILABILITY_OPTIONS,
  EXPERIENCE_OPTIONS,
  INSURANCE_OPTIONS,
  DISTANCE_OPTIONS,
  PRICE_OPTIONS,
  THERAPY_OPTIONS,
} from "constants/options";
import { DictionaryItem, dictionaryService } from "services/dictionaryService";
import { HttpService } from "services/httpService";
import { ListResponse } from "types/common.types";
import { TherapistId, TherapistList } from "types/therapist.types";
import { getCurrentPosition } from "utils/getCurrentPosition";
import { objectToQueryParams } from "utils/objectToQueryParams";
import { proxy } from "valtio";

type Position = {
  latitude: number;
  longitude: number;
};

export const listFilters = [
  "therapyValue",
  "professionalSpecialtyValue",
  "specialtyValue",
  "typesValue",
  "availabilityValue",
] as const;

export const singleFilters = [
  "distanceValue",
  "experienceValue",
  "priceValue",
  "insuranceValue",
] as const;

export const placeFilters = ["city", "state", "zip", "search"] as const;

export type ListFilter = typeof listFilters[number];

export type SingleFilter = typeof singleFilters[number];

export type Filter = ListFilter | SingleFilter;

export type PlaceFilter = typeof placeFilters[number];

type FilterItem = ListOption & { name: Filter | "search" };

export class ListingService extends HttpService {
  therapists: TherapistList = [];

  totalCount: number | null = null;

  isInitializing = true;

  isFetching = false;

  isError = false;

  prefix = "/v1/therapists";

  specialties: ListOption[] = [];

  therapies: ListOption[] = [];

  professionalSpecialties: ListOption[] = [];

  pageSize = 10;

  currentPage = 1;

  showFilters = false;

  geo: Position | null = null;

  therapyValue: ListOption[] = [];

  distanceValue: ListOption | null = null;

  professionalSpecialtyValue: ListOption[] = [];

  experienceValue: ListOption | null = null;

  priceValue: ListOption | null = null;

  specialtyValue: ListOption[] = [];

  typesValue: ListOption[] = [];

  insuranceValue: ListOption | null = null;

  availabilityValue: ListOption[] = [];

  search: string | null = null;

  city: string | null = null;

  state: string | null = null;

  zip: string | null = null;

  appliedFilters: FilterItem[] = [];

  constructor(args?: any) {
    super({
      ...args,
    });
  }

  async init(params?: URLSearchParams) {
    this.isInitializing = true;
    const [specialties, therapies, professionalSpecialties] = await Promise.all(
      [
        dictionaryService.getSpecialties(),
        dictionaryService.getTherapies(),
        dictionaryService.getProfessionalSpecialties(),
      ]
    );
    const mapper = (item: DictionaryItem) => ({
      value: item.code,
      label: item.name,
    });

    this.specialties = specialties
      .map(mapper)
      .filter(({ value }) => value !== "no-preference");
    this.therapies = therapies.map(mapper);
    this.professionalSpecialties = professionalSpecialties.map(mapper);

    if (params) {
      this.setFromSearchParams(params);
      this.getAppliedFilters();
    }

    if (this.search === "" && !this.zip && !this.city && !this.state) {
      this.setCurrentPosition();
    }

    this.isInitializing = false;
    this.getTherapists();
  }

  async setCurrentPosition({ fetch }: { fetch?: boolean } = {}) {
    try {
      this.isFetching = true;
      this.geo = await getCurrentPosition();
      if (fetch) await this.getTherapists();
    } catch (error) {
      console.log(error);
    } finally {
      this.isFetching = false;
    }
  }

  async getTherapists(): Promise<TherapistList> {
    try {
      this.isFetching = true;
      const data = (await this.http.get(
        `${this.prefix}/?${this.getQuery()}`
      )) as ListResponse<TherapistList>;
      this.therapists = data.results as TherapistList;
      this.totalCount = data.count;
      return this.therapists;
    } catch (e: any) {
      if (e.status === 404) {
        this.isError = true;
      }
      return this.therapists;
    } finally {
      this.isFetching = false;
    }
  }

  setFromSearchParams(search: URLSearchParams) {
    const options = {
      distanceValue: DISTANCE_OPTIONS,
      experienceValue: EXPERIENCE_OPTIONS,
      priceValue: PRICE_OPTIONS,
      insuranceValue: INSURANCE_OPTIONS,
      therapyValue: THERAPY_OPTIONS,
      professionalSpecialtyValue: this.professionalSpecialties,
      typesValue: this.therapies,
      specialtyValue: this.specialties,
      availabilityValue: AVAILABILITY_OPTIONS,
    };

    search.forEach((value, key) => {
      if (key === "currentPage") {
        this.currentPage = Number(value) || 1;
      }

      if ((placeFilters as readonly string[]).includes(key)) {
        const name = key as PlaceFilter;
        this[name] = value || null;
      }

      if ((singleFilters as readonly string[]).includes(key)) {
        const name = key as SingleFilter;
        this[name] =
          options[name].find((option) => option.value === value) || null;
      }

      if ((listFilters as readonly string[]).includes(key)) {
        const name = key as ListFilter;
        this[name] = options[name].filter((option) =>
          value.split(",").includes(option.value)
        );
      }
    });
  }

  getSearchParams() {
    const params: Record<string, string> = {};
    this.isError = false;
    params.currentPage = String(this.currentPage);

    singleFilters
      .filter((filterName) => this[filterName])
      .forEach((filterName) => {
        params[filterName] = this[filterName]?.value as string;
      });

    listFilters
      .filter((filterName) => this[filterName].length > 0)
      .forEach((filterName) => {
        params[filterName] = this[filterName]
          .map(({ value }) => value)
          .join(",");
      });

    placeFilters
      .filter((filterName) => this[filterName] !== null)
      .forEach((filterName) => {
        params[filterName] = this[filterName] as string;
      });

    return new URLSearchParams(params);
  }

  getQuery() {
    const params = {
      page_size: this.pageSize,
      page: this.currentPage,
      ...(this.therapyValue.length && {
        modalities: this.therapyValue.map((ter) => ter.value),
      }),
      ...this.geo,
      ...(this.professionalSpecialtyValue.length && {
        professional_specialties: this.professionalSpecialtyValue.map(
          (specialty) => specialty.value
        ),
      }),
      ...(this.experienceValue && {
        how_long_practicing: this.experienceValue.value,
      }),
      ...(this.specialtyValue.length && {
        specialties: this.specialtyValue.map((specialty) => specialty.value),
      }),
      ...(this.typesValue.length && {
        therapies: this.typesValue.map((type) => type.value),
      }),
      ...(this.insuranceValue && {
        accepts_insurance: this.insuranceValue.value,
      }),
      ...(this.availabilityValue.length && {
        availability: this.availabilityValue.map((type) => type.value),
      }),
      ...(this.priceValue &&
        // eslint-disable-next-line no-nested-ternary
        (this.priceValue.value.split("-").length > 1
          ? {
              in_person_cost_min: this.priceValue.value.split("-")[0],
              in_person_cost_max: this.priceValue.value.split("-")[1],
              online_cost_min: this.priceValue.value.split("-")[0],
              online_cost_max: this.priceValue.value.split("-")[1],
            }
          : this.priceValue.value.split("-")[0] === "0"
          ? { offer_free_call: true }
          : {
              in_person_cost_min: this.priceValue.value.split("-")[0],
              online_cost_min: this.priceValue.value.split("-")[0],
            })),
      ...(this.city && { city: this.city }),
      ...(this.state && { state: this.state }),
      ...(this.zip && { zip: this.zip }),
    };

    return new URLSearchParams(objectToQueryParams(params)).toString();
  }

  async like(id: TherapistId) {
    const therapist = this.therapists.find((t) => t.id === id);
    this.therapists = this.therapists.map((item) =>
      item.id === id
        ? { ...item, is_my_favourite: !item.is_my_favourite }
        : item
    );

    await this.http.post(`/v1/therapists/${id}/favourite/`, {
      is_my_favourite: !therapist?.is_my_favourite,
    });
  }

  addListFilter(name: ListFilter, value: ListOption) {
    if (this[name].some((option) => option.value === value.value)) return;

    this[name] = [...this[name], value];
    this.currentPage = 1;
    this.getAppliedFilters();
    this.getTherapists();
  }

  setListFilter(name: ListFilter, value: ListOption[]) {
    this[name] = [...value];

    this.currentPage = 1;
    this.getAppliedFilters();
    this.getTherapists();
  }

  setSingleFilter(name: SingleFilter, value: ListOption | null) {
    this[name] = value;

    this.currentPage = 1;
    this.getAppliedFilters();
    this.getTherapists();
  }

  setGeo(value: Position | null) {
    this.geo = value;
    this.currentPage = 1;
    this.getAppliedFilters();
    this.getTherapists();
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.getTherapists();
  }

  setPlaceFilter(args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
    withoutRequest?: boolean;
  }) {
    placeFilters.forEach((name) => {
      const argument = args[name];
      this[name] = typeof argument === "string" ? argument : null;
    });
    this.currentPage = 1;
    this.getAppliedFilters();

    if (!args.withoutRequest) {
      this.getTherapists();
    }
  }

  removeFilter(
    name: ListFilter | SingleFilter | "insuranceValue" | "search",
    value?: string
  ) {
    if (
      name === "therapyValue" ||
      name === "professionalSpecialtyValue" ||
      name === "specialtyValue" ||
      name === "typesValue" ||
      name === "availabilityValue"
    ) {
      this[name] = this[name].filter((item) => item.value !== value);
    }

    if (
      name === "insuranceValue" ||
      name === "distanceValue" ||
      name === "experienceValue" ||
      name === "priceValue"
    ) {
      this[name] = null;
    }

    if (name === "search") {
      this.search = null;
      this.city = null;
      this.state = null;
      this.zip = null;
    }

    this.getAppliedFilters();
    this.getTherapists();
  }

  clearFilters() {
    this.therapyValue = [];
    this.distanceValue = null;
    this.professionalSpecialtyValue = [];
    this.experienceValue = null;
    this.priceValue = null;
    this.specialtyValue = [];
    this.typesValue = [];
    this.insuranceValue = null;
    this.availabilityValue = [];
    this.search = null;
    this.city = null;
    this.state = null;
    this.zip = null;
    this.isError = false;

    this.getAppliedFilters();
    this.getTherapists();
  }

  private getAppliedFilters() {
    this.appliedFilters = [
      { name: "search", label: this.search, value: this.search },
      ...this.therapyValue.map((item) => ({
        ...item,
        name: "therapyValue",
      })),
      { ...this.distanceValue, name: "distanceValue" },
      ...this.professionalSpecialtyValue.map((item) => ({
        ...item,
        name: "professionalSpecialtyValue",
      })),
      { ...this.experienceValue, name: "experienceValue" },
      { ...this.priceValue, name: "priceValue" },
      ...this.specialtyValue.map((item) => ({
        ...item,
        name: "specialtyValue",
      })),
      ...this.typesValue.map((item) => ({ ...item, name: "typesValue" })),
      {
        ...this.insuranceValue,
        name: "insuranceValue",
        // eslint-disable-next-line no-nested-ternary
        label: this.insuranceValue
          ? this.insuranceValue.value === "true"
            ? "Insurance Accepted"
            : "Insurance not Accepted"
          : null,
      },
      ...this.availabilityValue.map((item) => ({
        ...item,
        name: "availabilityValue",
      })),
    ].filter((item) => item.label) as FilterItem[];
  }

  toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }
}

export const listingService = proxy(new ListingService());
