import { ListingLayout } from "components/Layout/ListingLayout";
import { LoadingHOC } from "components/LoadingHOC";
import { Pagination } from "components/Pagination";
import { TherapistListingItem } from "components/TherapistListingItem";
import { useListing } from "hooks/useListing";
import { useTitle } from "hooks/useTitle";
import { listingService } from "services/listingService";
import { FilterTags } from "./components/FilterTags";
import { Search } from "./components/Search";
import styles from "./Listing.module.scss";

export const listingPath = "/listing";

const formatTherapistsCount = (count: number | null) => {
  if (count === null || count < 1000) {
    return count;
  }

  return count > 1000 ? "1,000+" : "1,000";
};

export const Listing = () => {
  const {
    therapists,
    totalCount,
    isFetchingList,
    pageCount,
    currentPage,
    isError,
  } = useListing();
  useTitle("Therapass ESA: Listing");
  return (
    <ListingLayout>
      <h1 className={styles.listingHeader}>ESA therapists for you</h1>
      <p className={styles.listingResults}>
        {formatTherapistsCount(totalCount)} results
      </p>
      <Search />
      <FilterTags />
      <LoadingHOC
        isLoading={isFetchingList}
        className={styles.listingCatalog}
        text="Searching for therapists..."
      >
        {isError && !isFetchingList ? (
          <div className={styles.noAvailable}>This page does not exist</div>
        ) : (
          <>
            {therapists.length === 0 && !isFetchingList && (
              <div className={styles.noAvailable}>No available therapists</div>
            )}
            {therapists.map((therapist) => (
              <TherapistListingItem key={therapist.id} therapist={therapist} />
            ))}
            <Pagination
              pageCount={pageCount}
              currentPage={currentPage}
              goTo={(page) =>
                listingService.setCurrentPage(
                  page > pageCount ? pageCount : page
                )
              }
            />
          </>
        )}
      </LoadingHOC>
    </ListingLayout>
  );
};
