import { OPTIONS_ICONS } from "constants/icons";
import { availabilityOptions } from "constants/options";
import { ReactComponent as BrowserIcon } from "assets/icons/browser.svg";
import { ReactComponent as ChatIcon } from "assets/icons/comment-dots.svg";
import { ReactComponent as LandlineIcon } from "assets/icons/landline-phone.svg";
import { ReactComponent as MobileIcon } from "assets/icons/mobile-phone.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share.svg";
import cn from "classnames";
import { Availability } from "components/Availability";
import { Avatar } from "components/Avatar";
import { FormatProfileField } from "components/FormatProfileField";
import { Button } from "components/UIKit/Button";
import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { connectPath } from "pages/Connect";
import { stateLicenseService } from "services/stateLicenseService";
import { therapistProfileService } from "services/therapistProfileService";
import { formatPhone } from "utils/formatPhone";
import { getWebsiteAddress } from "utils/getWebsiteAddress";
import { stateLicenseList } from "utils/stateLicenseList";
import { therapistName } from "utils/therapistName";
import { useSnapshot } from "valtio";
import { VerifiedCircle } from "../../blocks/VerifiedCircle/VerifiedCircle";
import styles from "./PatientsTherapistHeaderMobile.module.scss";

export const PatientsTherapistHeaderMobile = () => {
  const shareData = {
    title: "TheraPass ESA",
    text: "Therapist profile.",
    url: window.location.href,
  };

  const onShareButton = async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(err);
    }
  };

  const { currentProfile } = useSnapshot(therapistProfileService);
  const { data } = useSnapshot(stateLicenseService);
  const phoneToDisplay =
    currentProfile?.user.mobile_phone || currentProfile?.phone;
  const companyPhone = currentProfile?.business_info?.phone;
  const showMobilePhone = currentProfile
    ? !currentProfile?.user.hide_mobile_phone_number
    : true;
  const showCompanyPhone = currentProfile
    ? !currentProfile?.business_info?.hide_company_phone_number
    : true;
  const stateLicenses = stateLicenseList([...data], "license_state");

  const withStatus =
    currentProfile && currentProfile?.online_chat_sessions_availability;

  const specialties = useDictionaryItemNames(
    "Issues",
    currentProfile?.specialties
  );

  const professionalSpecialtyNames = useDictionaryItemNames(
    "ProfessionalSpecialties",
    currentProfile?.professional_specialties
  );

  if (currentProfile === null) return null;

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <Avatar
          className={styles.img}
          alt={therapistName({
            first_name: currentProfile.user.first_name,
            last_name: currentProfile.user.last_name,
          })}
          file={currentProfile?.avatar}
          chat_status={withStatus ? currentProfile.chat_status : undefined}
          withZoom
          id={currentProfile.id}
        />
        <div className={styles.info}>
          <Availability
            isAvailable={currentProfile?.is_available}
            className={styles.available}
          />
          <p className={styles.name}>
            <FormatProfileField maxLength={40}>
              {therapistName({
                first_name: currentProfile.user.first_name,
                last_name: currentProfile.user.last_name,
              })}
            </FormatProfileField>
            <VerifiedCircle withoutText size="sm" className={styles.nameIcon} />
          </p>
          {currentProfile.company_name &&
            currentProfile.business_info?.use_company_name_also &&
            (currentProfile.website ? (
              <a
                className={styles.company}
                href={getWebsiteAddress(currentProfile.website)}
              >
                {currentProfile.company_name}
              </a>
            ) : (
              <p className={styles.company}>{currentProfile.company_name}</p>
            ))}
          <p className={styles.subtitle}>
            {currentProfile.credentials_choice && (
              <em>
                <FormatProfileField shouldLimitLength={false}>
                  {currentProfile.credentials_choice}
                </FormatProfileField>
              </em>
            )}
            {currentProfile?.source?.includes("imported") &&
              professionalSpecialtyNames.length > 0 && (
                <FormatProfileField
                  fallbackText="No Educational Specialties specified"
                  shouldLimitLength={false}
                >
                  {professionalSpecialtyNames.filter((item) => item).join(", ")}
                </FormatProfileField>
              )}
            {data && (
              <FormatProfileField shouldLimitLength={false}>
                {`Licensed in: ${stateLicenses}`}
              </FormatProfileField>
            )}
          </p>
        </div>
      </div>
      <div className={styles.options}>
        <p className={styles.optionsText}>Therapy Options:</p>
        <ul className={styles.optionsContainer}>
          <FormatProfileField fallbackText="No options">
            {OPTIONS_ICONS.filter(
              (option) => currentProfile && currentProfile[option.name]
            ).map(({ name, component: Component }) => (
              <li
                key={name}
                className={styles.optionsItem}
                aria-label={availabilityOptions[name]}
              >
                <Component />
              </li>
            ))}
          </FormatProfileField>
        </ul>
      </div>
      <div className={styles.contacts}>
        {showMobilePhone && (
          <a
            href={phoneToDisplay ? `tel:${phoneToDisplay}` : ""}
            className={cn(styles.phone, !phoneToDisplay && styles.disabled)}
          >
            <span className={styles.phoneIcon}>
              <MobileIcon />
            </span>
            <span className={styles.phoneText}>
              <span>{formatPhone(phoneToDisplay)}</span>
            </span>
          </a>
        )}
        <Button className={styles.contactsButton} onClick={onShareButton}>
          <ShareIcon />
        </Button>
        {currentProfile?.website && (
          <a
            className={cn(styles.contactsButton)}
            href={getWebsiteAddress(currentProfile?.website)}
            target="_blank"
            rel="noreferrer"
          >
            <BrowserIcon />
          </a>
        )}
      </div>
      {showCompanyPhone && (
        <a
          href={companyPhone ? `tel:${companyPhone}` : ""}
          className={cn(
            styles.phone,
            styles.companyPhone,
            !companyPhone && styles.disabled
          )}
        >
          <span className={styles.phoneIcon}>
            <LandlineIcon />
          </span>
          <span className={styles.phoneText}>
            <span>{formatPhone(companyPhone)}</span>
          </span>
        </a>
      )}
      <Button
        isLink
        to={`${connectPath}?therapistId=${currentProfile.id}`}
        className={cn(
          styles.button,
          styles.premium,
          withStatus && styles[`status_${currentProfile.chat_status}`]
        )}
      >
        <ChatIcon />
        <FormatProfileField maxLength={25}>
          {`Connect with ${therapistName({
            title: currentProfile.title,
            last_name: currentProfile.user.last_name,
          })}`}
        </FormatProfileField>
      </Button>
      {currentProfile?.source?.includes("imported") &&
        professionalSpecialtyNames.length > 0 && (
          <ul className={styles.list}>
            <FormatProfileField fallbackText="No Educational Specialties specified">
              {specialties.length > 0 &&
                specialties.map((specialty) => (
                  <li className={styles.listItem} key={specialty}>
                    {specialty}
                  </li>
                ))}
            </FormatProfileField>
          </ul>
        )}
    </div>
  );
};
