import infoBadge from "assets/icons/info-badge.png";
import cn from "classnames";
import { FC } from "react";
import styles from "./Badge.module.scss";

interface Props {
  text?: string;
  className?: string;
}

export const Badge: FC<Props> = ({ text, className }) => {
  return (
    <div className={cn(className, styles.badge)}>
      <img src={infoBadge} alt="info" />
      {text}
    </div>
  );
};
