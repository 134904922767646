import { useFormPersist } from "hooks/useFormPersist";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { omit } from "lodash";
import { connectPath } from "pages/Connect/Connect";
import { successPath } from "pages/Connect/Success";
import { SubmitHandler } from "react-hook-form";

export type ConnectFormValues = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  agreeDisclosure: boolean;
  agreeReceiveTexts: boolean;
};

const defaultValues: ConnectFormValues = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  agreeDisclosure: false,
  agreeReceiveTexts: false,
};

export const CONNECT_FORM_STORAGE_KEY = "connectData";

export const useConnect = () => {
  const navigate = useNavigateWithQuery();
  const methods = useFormPersist(CONNECT_FORM_STORAGE_KEY, {
    defaultValues,
  });

  const onSubmit: SubmitHandler<ConnectFormValues> = (data) => {
    try {
      const dataToApi = omit(data, ["agreeDisclosure", "agreeReceiveTexts"]);

      // TODO: Send data to server
      console.log("Sending form data", dataToApi);
      localStorage.removeItem(CONNECT_FORM_STORAGE_KEY);
      navigate(`${connectPath}${successPath}`);
    } catch (error) {
      console.error("Error sending data", error);
    }
  };

  return { ...methods, onSubmit };
};
