import axios, { AxiosResponse, AxiosError } from "axios";
import { stringify } from "qs";
import { generalLogicService } from "services/generalLogicService";
import { apiEndpoint } from "utils/env";

const interceptorSuccessCb = (response: AxiosResponse) => response.data;

const interceptorFailureCb = (err: AxiosError) => {
  if (!err.response) {
    return Promise.reject(err);
  }

  const { status } = err.response;

  const serverError = status >= 500;
  if (serverError) {
    generalLogicService.setError({ status });
  }

  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw err.response;
};

const axiosInstanceFabric = ({ baseURL }: { baseURL: string }) => {
  const instance = axios.create({
    baseURL,
    paramsSerializer: {
      serialize: (params) => stringify(params, { arrayFormat: "comma" }),
    },
  });

  instance.interceptors.response.use(
    interceptorSuccessCb,
    interceptorFailureCb
  );

  return instance;
};

export const defaultHTTPClient = axiosInstanceFabric({ baseURL: apiEndpoint });
