import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import cn from "classnames";
import { FC } from "react";
import styles from "./ConfirmFormValue.module.scss";

interface Props {
  label: string;
  value: string;
  onClick: () => void;
}

export const ConfirmFormValue: FC<Props> = ({ label, value, onClick }) => {
  return (
    <div className={cn(styles.confirmFormValue)}>
      <p>
        {label}: {value}
      </p>
      <button onClick={onClick}>
        <EditIcon />
      </button>
    </div>
  );
};
