import { Pagination } from "types/pagination.types";
import { Therapist, TherapistId, TherapistFile } from "types/therapist.types";
import { getAvatar } from "utils/getAvatar";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { HttpService } from "./httpService";

export interface TherapistProfile extends Therapist {
  avatar?: TherapistFile;
  video?: TherapistFile;
  photos: readonly TherapistFile[];
  credentialFiles: readonly TherapistFile[];
  longitude?: number;
  latitude?: number;
  is_available: boolean;
}

type Cache = { [key: TherapistId]: TherapistProfile };

class TherapistProfileService extends HttpService {
  private cache: Cache = {};

  isFetching = false;

  currentProfile: TherapistProfile | null = null;

  async getById(id: TherapistId): Promise<Therapist> {
    this.isFetching = true;
    if (this.cache[id]) {
      this.currentProfile = this.cache[id];
    } else {
      this.currentProfile = null;
    }
    const therapist: Therapist = await this.http.get(`/v1/therapists/${id}/`);

    const { results: files }: Pagination<TherapistFile> = await this.http.get(
      `/v1/therapists/${id}/files/`
    );

    const avatar = getAvatar(files);
    const video = files.find(({ mime }) => mime.includes("video"));
    const photos = files.filter(
      ({ mime, type }) =>
        type === "photo_and_video_intro" && mime.includes("image")
    );
    const credentialFiles = files.filter(({ type }) => type === "credential");

    this.cache[id] = {
      ...therapist,
      avatar,
      video,
      photos,
      credentialFiles,
      is_available: Boolean(
        therapist.telephone_session_availability ||
          therapist.video_session_availability ||
          therapist.online_chat_sessions_availability ||
          therapist.in_person_session_availability
      ),
    };

    this.isFetching = false;
    this.currentProfile = this.cache[id];

    return this.currentProfile;
  }

  async like() {
    if (this.currentProfile === null) return;

    this.currentProfile.is_my_favourite = !this.currentProfile.is_my_favourite;

    await this.http.post(
      `/v1/therapists/${this.currentProfile.id}/favourite/`,
      {
        is_my_favourite: !this.currentProfile.is_my_favourite,
      }
    );
  }
}

export const therapistProfileService = proxy(new TherapistProfileService());

devtools(therapistProfileService, { name: "therapistProfileService" });
