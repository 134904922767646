import cn from "classnames";
import React, { CSSProperties, forwardRef, useId, useState } from "react";
import styles from "./Input.module.scss";

enum InputSize {
  full = "full",
  auto = "auto",
}

type InputSizeType = `${InputSize}`;

interface Props extends Omit<React.HTMLProps<HTMLInputElement>, "size"> {
  size?: InputSizeType;
  label?: string;
  error?: string;
  optional?: string;
  description?: string;
  descriptionAlignment?: "left" | "right";
  inputStyles?: CSSProperties;
}

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      size = "full",
      label,
      id,
      type = "text",
      className,
      error,
      optional,
      style,
      description,
      descriptionAlignment = "right",
      inputStyles,
      ...props
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);
    const randomId = useId();
    const inputId = id || randomId;

    return (
      <div
        className={cn(
          styles.field,
          className,
          { [styles.inputOptional]: optional },
          props.disabled && styles.disabled
        )}
      >
        {label && (
          <label htmlFor={inputId} className={styles.label}>
            {label}
          </label>
        )}
        <div
          className={cn(
            styles.inputHolder,
            error && styles.error,
            isActive && styles.active
          )}
          data-optional={optional}
        >
          <input
            ref={ref}
            id={inputId}
            type={type}
            {...props}
            className={cn(`btn-size-${size}`, styles.input)}
            onFocus={(e) => {
              setIsActive(true);
              if (props?.onFocus) {
                props.onFocus(e);
              }
            }}
            onBlur={(e) => {
              setIsActive(false);
              if (props?.onBlur) {
                props.onBlur(e);
              }
            }}
            style={inputStyles}
          />
        </div>
        {description && !error && (
          <p
            className={cn(styles.description, cn(styles[descriptionAlignment]))}
          >
            {description}
          </p>
        )}
        {error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    );
  }
);
