import { FUNNEL_QUESTIONS } from "constants/funnel";
import { useFormPersist } from "hooks/useFormPersist";
import { useModal } from "hooks/useModal";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { funnelPath } from "pages/Funnel";
import { successPath } from "pages/Funnel/Success";
import { useRef } from "react";
import { SubmitHandler } from "react-hook-form";

export type FunnelFormNames = typeof FUNNEL_QUESTIONS[number]["name"];
export type FunnelFormValues = Record<FunnelFormNames, string>;

const defaultValues = FUNNEL_QUESTIONS.reduce(
  (acc, question) => ({ ...acc, [question.name]: undefined }),
  {} as FunnelFormValues
);

export const FUNNEL_FORM_STORAGE_KEY = "funnelData";

export const useFunnel = () => {
  const navigate = useNavigateWithQuery();
  const promiseResolveRef = useRef<(value: boolean) => void>();
  const { open } = useModal("CONTINUE_SURVEY");

  const methods = useFormPersist(FUNNEL_FORM_STORAGE_KEY, {
    defaultValues,
    initialPathname: `${funnelPath}/0`,
    shouldRememberPathname: true,
    askIfShouldRestoreValues: () => {
      open();
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
      });
    },
  });

  const onSubmit: SubmitHandler<FunnelFormValues> = (data) => {
    try {
      // TODO: Send data to server
      console.log("Sending form data", data);
      localStorage.removeItem(FUNNEL_FORM_STORAGE_KEY);
      navigate(`${funnelPath}${successPath}`);
    } catch (error) {
      console.error("Error sending data", error);
    }
  };

  return { ...methods, onSubmit, resolve: promiseResolveRef.current };
};
