import {
  TherapistProfile,
  therapistProfileService,
} from "services/therapistProfileService";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";
import { HttpService } from "./httpService";

export type PetKind = "cat" | "dog";

export type OfferType =
  | "assistance_animal_rights_attorney_protection"
  | "landlord_guaranteed_acceptance_insurance"
  | "assistance_animal_gear";

export interface PetInfo {
  kind: PetKind;
  name: string;
  weight: number;
}

export interface Pet extends PetInfo {
  id: number;
}

export interface Address {
  street_address: string;
  city: string;
  zip: string;
  state: string;
}

export interface PaymentData {
  cardholder_name: string;
  card_number: string;
  exp_date: string;
  cvv_code: string;
}

class FinalCheckoutService extends HttpService {
  public pets: Pet[] = [];

  public offers: Record<OfferType, boolean> = {
    assistance_animal_rights_attorney_protection: false,
    landlord_guaranteed_acceptance_insurance: false,
    assistance_animal_gear: false,
  };

  public shippingAddress: Address | null = null;

  public paymentData: PaymentData | null = null;

  public therapist: TherapistProfile | null = null;

  public paymentStatus: "success" | "error" | null = null;

  private counter = 1;

  addPet(petInfo: PetInfo) {
    this.pets = [...this.pets, { id: this.counter, ...petInfo }];
    this.counter += 1;
  }

  editPet(id: number, petInfo: PetInfo) {
    const index = this.pets.findIndex((pet) => pet.id === id);

    console.log("editPet", index, petInfo);

    if (index < 0) return;

    this.pets = [
      ...this.pets.slice(0, index),
      { id: this.pets[index].id, ...petInfo },
      ...this.pets.slice(index + 1),
    ];
  }

  removePet(id: number) {
    this.pets = this.pets.filter((pet) => pet.id !== id);
  }

  selectOffer(name: OfferType, value: boolean) {
    this.offers[name] = value;
  }

  setShippingAddress(address: Address) {
    this.shippingAddress = { ...address };
  }

  async setPaymentData(paymentData: PaymentData) {
    this.paymentData = paymentData;
    const id = "rN7NAZL";

    if (this.paymentData.exp_date === "12/12") {
      this.paymentStatus = "error";
    } else {
      this.paymentStatus = "success";
    }

    await therapistProfileService.getById(id);
    this.therapist = therapistProfileService.currentProfile;
  }
}

export const finalCheckoutService = proxy(new FinalCheckoutService());

devtools(finalCheckoutService, { name: "finalCheckoutService" });
