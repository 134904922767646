import cn from "classnames";
import { ContinueSurveyModal } from "components/ContinueSurveyModal";
import { Button } from "components/UIKit/Button";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { funnelPath } from "pages/Funnel/Funnel";
import { useFunnel } from "pages/Funnel/useFunnel";
import { FC } from "react";
import { FunnelQuestion } from "types/funnel.types";
import { Inputs } from "./inputs";
import styles from "./Question.module.scss";

interface Props {
  question: FunnelQuestion;
  index: number;
  position: "first" | "middle" | "last";
}

export const Question: FC<Props> = ({ question, index, position }) => {
  const navigate = useNavigateWithQuery();
  const { control, handleSubmit, onSubmit, watch, resolve } = useFunnel();
  const value = watch(question.name);
  const isDisabled = !value;

  return (
    <form
      className={cn(styles.question, styles[question.type])}
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1>{question.label}</h1>
      <Inputs question={question} key={question.name} control={control} />
      <div className={styles.buttons}>
        {position !== "first" && (
          <Button
            color="greenlight"
            size="full"
            onClick={() => navigate(`${funnelPath}/${index - 1}`)}
          >
            Back
          </Button>
        )}
        {position !== "last" && (
          <Button
            size="full"
            onClick={() => navigate(`${funnelPath}/${index + 1}`)}
            disabled={isDisabled}
          >
            Next
          </Button>
        )}
        {position === "last" && (
          <Button size="full" type="submit" disabled={isDisabled}>
            Finish
          </Button>
        )}
      </div>
      <ContinueSurveyModal resolve={resolve} />
    </form>
  );
};
