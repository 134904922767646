import { useEffect, useState } from "react";
import { DictionaryItem, dictionaryService } from "services/dictionaryService";

type DictionaryName =
  | "Issues"
  | "Languages"
  | "Therapies"
  | "AlliedGroups"
  | "HealthInsuranceCompanies"
  | "InsuranceCompanies"
  | "ProfessionalSpecialties";

export const useDictionaryItemNames = (
  dictionaryName: DictionaryName,
  codes?: readonly string[]
) => {
  const [currentItems, setCurrentItems] = useState<
    (DictionaryItem | undefined)[]
  >([]);

  useEffect(() => {
    (async () => {
      if (!codes) {
        return;
      }

      try {
        const results = await dictionaryService[`get${dictionaryName}`]();
        setCurrentItems(
          codes?.map((code) => results.find((item) => item.code === code))
        );
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dictionaryName, codes]);

  return currentItems.map((item) => item?.name || "");
};
