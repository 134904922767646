import { CustomRouter } from "components/CustomRouter";
import { Connect, connectPath } from "pages/Connect";
import { FinalCheckout, finalCheckoutPath } from "pages/FinalCheckout";
import { Funnel, funnelPath } from "pages/Funnel";
import { Home, homePath } from "pages/Home";
import { Listing, listingPath } from "pages/Listing";
import { NotFound } from "pages/NotFound";
import { Therapist, therapistPath } from "pages/Therapist";
import { Route, Routes } from "react-router-dom";
import history from "utils/history";

function App() {
  return (
    <CustomRouter history={history}>
      <Routes>
        <Route path={homePath} element={<Home />} />
        <Route path={listingPath} element={<Listing />} />
        <Route path={therapistPath} element={<Therapist />} />
        <Route path={finalCheckoutPath} element={<FinalCheckout />} />
        <Route path={`${connectPath}/*`} element={<Connect />} />
        <Route path={`${funnelPath}/*`} element={<Funnel />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </CustomRouter>
  );
}

export default App;
