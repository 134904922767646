import cn from "classnames";
import { FC, forwardRef, HTMLProps } from "react";
import styles from "./ChoiceCard.module.scss";

interface Props extends HTMLProps<HTMLInputElement> {
  icon?: FC<HTMLProps<SVGElement>>;
  label?: string;
  hasLabel?: boolean;
}

export const ChoiceCard = forwardRef<HTMLInputElement, Props>(
  (
    { className, icon: Icon, label, hasLabel = true, checked, ...props },
    ref
  ) => {
    return (
      <label
        className={cn(className, styles.choiceCard, checked && styles.checked)}
      >
        <input type="radio" ref={ref} checked={checked} {...props} />
        {Icon && <Icon alt={label} />}
        {hasLabel ? label : null}
      </label>
    );
  }
);
