import { ReactComponent as LogoWithText } from "assets/images/logo-with-text.svg";
import { Header } from "components/Header/Header";
import { homePath } from "pages/Home";
import { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./SimpeHeader.module.scss";

export const SimpleHeader: FC = () => (
  <Header className={styles.simpleHeader}>
    <Link to={homePath}>
      <LogoWithText className={styles.logo} />
    </Link>
  </Header>
);
