import classNames from "classnames";
import { Button } from "components/UIKit/Button";
import { Input } from "components/UIKit/Input";
import { REQUIRED_MESSAGE } from "pages/FinalCheckout/constants";
import { StepProps } from "pages/FinalCheckout/types";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import {
  PaymentData,
  finalCheckoutService,
} from "services/finalCheckoutService";
import isCreditCard from "validator/es/lib/isCreditCard";
import { useSnapshot } from "valtio";

import styles from "../../FinalCheckout.module.scss";

export const PaymentInfo: FC<StepProps> = ({
  handlePrevStep,
  handleNextStep,
}) => {
  const { paymentData } = useSnapshot(finalCheckoutService);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PaymentData>({
    defaultValues: paymentData || {},
  });

  const onSubmit = (data: PaymentData) => {
    finalCheckoutService.setPaymentData(data);
    handleNextStep();
  };

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Enter your payment info</h1>
      <div style={{ maxWidth: 370, width: "100%" }}>
        <div className={styles.inputs}>
          <div className={styles.row}>
            <Input
              placeholder="Cardholder name"
              {...register("cardholder_name", { required: REQUIRED_MESSAGE })}
              error={errors.cardholder_name?.message}
            />
          </div>

          <div className={styles.row}>
            <Controller
              name="card_number"
              control={control}
              rules={{
                required: REQUIRED_MESSAGE,
                validate: (value) =>
                  isCreditCard(value.replace(/[\s_]/g, "")) ||
                  "Incorrect card number.",
              }}
              render={({ field: { value, onChange }, fieldState }) => (
                <InputMask
                  mask="9999 9999 9999 9999"
                  value={value}
                  onChange={onChange}
                  className={styles.input}
                >
                  <Input
                    placeholder="Card number"
                    error={fieldState.error?.message}
                  />
                </InputMask>
              )}
            />
          </div>

          <div className={classNames(styles.row, styles["--no-split"])}>
            <Controller
              name="exp_date"
              control={control}
              rules={{
                required: REQUIRED_MESSAGE,
                validate: (value) =>
                  /(^\d{2}\/\d{2}$)/.test(value) ||
                  "Should be valid Expiration Date",
              }}
              render={({ field: { value, onChange }, fieldState }) => (
                <InputMask
                  mask="99/99"
                  value={value}
                  onChange={onChange}
                  className={styles.input}
                >
                  <Input
                    placeholder="Expiration date"
                    error={fieldState.error?.message}
                  />
                </InputMask>
              )}
            />

            <Input
              placeholder="CVV code"
              {...register("cvv_code", {
                required: REQUIRED_MESSAGE,
                validate: (value) =>
                  /(^\d{3,5}$)/.test(value) || "Should be valid CVV code",
              })}
              error={errors.cvv_code?.message}
              type="password"
              description="3 digits on back"
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonsRow}>
        <Button color="white" onClick={handlePrevStep}>
          Back
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Next</Button>
      </div>
    </div>
  );
};
