import { FC, useState } from "react";

import { Confirmation } from "./steps/Confirmation";
import { Offers } from "./steps/Offers";
import { PaymentInfo } from "./steps/PaymentInfo";
import { PetsPage } from "./steps/PetsPage";
import { ShippingAddress } from "./steps/ShippingAddress";

export const finalCheckoutPath = "/final_checkout/";

interface StepProps {
  handlePrevStep: () => void;
  handleNextStep: () => void;
}

const steps: FC<StepProps>[] = [
  PetsPage,
  Offers,
  ShippingAddress,
  PaymentInfo,
  Confirmation,
];

export const FinalCheckout = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const StepComponent = steps[currentStep];

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
  };

  const handlePrevStep = () => {
    if (currentStep > 0) setCurrentStep(currentStep - 1);
  };

  return (
    <StepComponent
      handleNextStep={handleNextStep}
      handlePrevStep={handlePrevStep}
    />
  );
};
