import { useLocation, useNavigate } from "react-router-dom";

export const useNavigateWithQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (pathname: string, query?: string) => {
    navigate({ pathname, search: query ?? location.search });
  };
};
