import { InputSmartSearch } from "components/UIKit/InputSmartSearch";
import { listingService } from "services/listingService";
import { useSnapshot } from "valtio";
import styles from "./Search.module.scss";

export const Search = () => {
  const { search } = useSnapshot(listingService);

  const handleSelectPlace = (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
  }) => {
    listingService.setPlaceFilter(args);
  };

  return (
    <div className={styles.root}>
      <InputSmartSearch
        placeholder="Zip / City"
        value={search || ""}
        className={styles.container}
        optionsClassName={styles.options}
        onSelectPlace={handleSelectPlace}
      />
    </div>
  );
};
