import { Button } from "components/UIKit/Button";
import { Input } from "components/UIKit/Input";
import { InputPhone } from "components/UIKit/InputPhone";
import { useNavigateWithQuery } from "hooks/useNavigateWithQuery";
import { connectPath } from "pages/Connect";
import { confirmPath } from "pages/Connect/Confirm";
import { useConnect } from "pages/Connect/useConnect";
import isEmail from "validator/es/lib/isEmail";
import styles from "./Start.module.scss";

export const startPath = "/";

export const Start = () => {
  const navigate = useNavigateWithQuery();
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useConnect();

  const goToNextStep = () => navigate(`${connectPath}${confirmPath}`);

  return (
    <div className={styles.startPage}>
      <h1 className={styles.title}>Connect with an ESA Therapist now</h1>
      <p className={styles.subtitle}>
        First, we need your permission for an ESA Therapist to text you and then
        we will ask you a series of clinical questions to help us understand
        your emotional state.
      </p>
      <form className={styles.form} onSubmit={handleSubmit(goToNextStep)}>
        <div className={styles.inputs}>
          <div className={styles.row}>
            <Input
              placeholder="First Name"
              {...register("first_name", {
                required: "This field is required",
              })}
              className={styles.input}
              error={errors.first_name?.message}
            />
            <Input
              placeholder="Last Name"
              {...register("last_name", {
                required: "This field is required",
              })}
              className={styles.input}
              error={errors.last_name?.message}
            />
          </div>

          <InputPhone
            control={control}
            name="phone"
            className={styles.input}
            placeholder="Mobile Phone Number"
            rules={{ required: "This field is required" }}
          />

          <Input
            type="email"
            placeholder="Email Address"
            {...register("email", {
              required: "This field is required",
              validate: (value) => isEmail(value) || "Invalid email",
            })}
            className={styles.input}
            error={errors.email?.message}
          />
        </div>

        <Button type="submit">Next</Button>
      </form>
    </div>
  );
};
