import { TherapistCard } from "components/TherapistCard";
import { capitalize } from "lodash";
import { therapistProfileService } from "services/therapistProfileService";
import { useSnapshot } from "valtio";
import styles from "./Success.module.scss";

export const successPath = "/success";

export const Success = () => {
  const { currentProfile } = useSnapshot(therapistProfileService);

  const subtitleName = [
    `${currentProfile?.user.first_name} ${currentProfile?.user.last_name}`,
    capitalize(currentProfile?.title),
  ]
    .filter((item) => item)
    .join(", ");

  return (
    <div className={styles.successPage}>
      <h1 className={styles.title}>Your message has been sent!</h1>
      <p className={styles.subtitle}>
        Your text message has been sent to {subtitleName}. Please allow a couple
        minutes for your ESA therapist {currentProfile?.user.first_name} to text
        you.
      </p>

      {currentProfile && <TherapistCard therapist={currentProfile} />}
    </div>
  );
};
