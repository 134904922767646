import avatar from "assets/images/avatar.png";
import classNames from "classnames";
import { ChatStatusBadge } from "components/ChatStatusBadge";
import { VerifiedCircle } from "components/VerifiedCircle";
import { therapistPath } from "pages/Therapist";
import { Link, useLocation } from "react-router-dom";
import { ChatStatus, TherapistFile } from "types/therapist.types";
import styles from "./Avatar.module.scss";

type Props = {
  file?: TherapistFile;
  alt?: string;
  className?: string;
  size?: "lg" | "md" | "sm";
  chat_status?: ChatStatus;
  onClick?: () => void;
  withZoom?: boolean;
  isVerified?: boolean;
  id?: string;
  planName?: string;
  withoutLink?: boolean;
};

export const Avatar = ({
  file,
  size = "md",
  className,
  chat_status,
  alt,
  onClick,
  isVerified,
  id,
  planName,
  withoutLink,
}: Props) => {
  const { pathname } = useLocation();

  return (
    <div className={classNames(styles.root, styles[size], className)}>
      {isVerified && <VerifiedCircle className={styles.verified} withoutText />}
      <Link
        to={withoutLink ? "#" : therapistPath.replace(":id", String(id))}
        state={{ prevPage: pathname }}
      >
        <img
          className={styles.image}
          alt={alt}
          src={(!file?.mime.match("video") && file?.file) || avatar}
        />
      </Link>
      {chat_status && planName === "Premium" && (
        <ChatStatusBadge
          className={styles.badge}
          chatStatus={chat_status}
          onClick={onClick}
        />
      )}
    </div>
  );
};
