import { ReactComponent as RedUserIcon } from "assets/icons/unavailable-user.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import cn from "classnames";
import "./Availability.scss";

interface Props {
  className?: string;
  isAvailable?: boolean;
}

export const Availability = ({ className, isAvailable }: Props) => {
  return (
    <div
      className={cn("available", className, { "available--red": !isAvailable })}
    >
      {isAvailable ? <UserIcon /> : <RedUserIcon />}
      <span>
        {isAvailable
          ? "Available for new clients"
          : "Unavailable for new clients"}
      </span>
    </div>
  );
};
