import { TherapistCollectionService } from "services/therapistCollectionService";
import { StateLicense, StateLicenseId } from "types/stateLicense.types";
import { proxy } from "valtio";
import { devtools } from "valtio/utils";

class StateLicenseService extends TherapistCollectionService<
  StateLicenseId,
  StateLicense
> {
  constructor(props?: any) {
    super({ ...props, collectionString: "/state-licenses" });
  }
}

export const stateLicenseService = proxy(new StateLicenseService());

devtools(stateLicenseService, { name: "stateLicenseService" });
