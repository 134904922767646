import { HomeLayout } from "components/Layout/HomeLayout";
import { InputSmartSearch } from "components/UIKit/InputSmartSearch";
import { Player } from "components/Video/Player";
import { useTitle } from "hooks/useTitle";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { listingService } from "services/listingService";
import styles from "./Home.module.scss";

export const homePath = "/";

export const Home = () => {
  useTitle("TheraPass ESA");
  const navigate = useNavigate();

  const handleSelectPlace = (args: {
    search?: string;
    city?: string;
    state?: string;
    zip?: string;
  }) => {
    listingService.setPlaceFilter({
      ...(args?.city && { city: args.city }),
      ...(args?.search && { search: args.search }),
      ...(args?.state && { state: args.state }),
      ...(args?.zip && { zip: args.zip }),
      withoutRequest: true,
    });
  };

  useEffect(() => {
    handleSelectPlace({ search: "" });
  }, []);

  return (
    <HomeLayout>
      <div className={styles.homePage}>
        <div className={styles.content}>
          <h1>Connect with an ESA Therapist now</h1>
          <p>Skip the online ESA promises and noise</p>
          <p>
            Get your ESA directly rom the source: +900,000 state licensed
            therapists
          </p>

          <div className={styles.search}>
            <InputSmartSearch
              placeholder="Zip Code / City"
              onSelectPlace={handleSelectPlace}
              maxLength={200}
              max={200}
              withButton
              buttonClass={styles.bottomBtn}
              className={styles.searchInput}
              buttonLabel="Find a therapist"
              onButtonClick={() =>
                navigate("/listing", { state: { withScroll: true } })
              }
            />
          </div>
        </div>
        <Player src="videos/home.mp4" className={styles.video} />
      </div>
    </HomeLayout>
  );
};
