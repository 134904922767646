import { ChoiceCard } from "components/UIKit/ChoiceCard";
import { Radio } from "components/UIKit/Radio";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { FunnelQuestion } from "types/funnel.types";
import { FunnelFormValues } from "../../useFunnel";
import styles from "./inputs.module.scss";

interface Props {
  question: FunnelQuestion;
  control: Control<FunnelFormValues>;
}

export const Inputs: FC<Props> = ({ question, control }) => (
  <Controller
    name={question.name}
    control={control}
    rules={{ required: true }}
    render={({ field }) => (
      <div className={styles[question.type]}>
        {question.options.map(({ label, value, icon }, index) => {
          const Component = question.type === "stack" ? Radio : ChoiceCard;

          return (
            <Component
              key={index}
              label={label}
              checked={field.value === value}
              value={value}
              onChange={() => field.onChange(value)}
              icon={icon}
              {...(question.type === "horizontalWithoutLabel" && {
                hasLabel: true,
              })}
            />
          );
        })}
      </div>
    )}
  />
);
