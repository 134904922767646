import { ReactComponent as ChatIcon } from "assets/icons/comment-dots.svg";
import { ReactComponent as LandlineIcon } from "assets/icons/landline-phone.svg";
import { ReactComponent as MobileIcon } from "assets/icons/mobile-phone.svg";
import cn from "classnames";
import { Button } from "components/UIKit/Button";
import { connectPath } from "pages/Connect";
import { therapistProfileService } from "services/therapistProfileService";
import { formatPhone } from "utils/formatPhone";
import { useSnapshot } from "valtio";
import { TherapistMapList } from "../TherapistMapList";
import styles from "./TherapistRightSide.module.scss";

export const TherapistRightSide = () => {
  const { currentProfile } = useSnapshot(therapistProfileService);

  const phoneToDisplay =
    currentProfile?.user.mobile_phone || currentProfile?.phone;
  const companyPhone = currentProfile?.business_info?.phone;
  const showMobilePhone = currentProfile
    ? !currentProfile?.user.hide_mobile_phone_number
    : true;
  const showCompanyPhone = currentProfile
    ? !currentProfile?.business_info?.hide_company_phone_number
    : true;

  return (
    <aside className={styles.root}>
      {showMobilePhone && (
        <a
          href={phoneToDisplay ? `tel:${phoneToDisplay}` : ""}
          className={cn(styles.phone, !phoneToDisplay && "disabled")}
        >
          <span className={styles.phoneIcon}>
            <MobileIcon />
          </span>
          <span className={styles.phoneText}>
            <span>{formatPhone(phoneToDisplay)}</span>
          </span>
        </a>
      )}
      {showCompanyPhone && (
        <a
          href={companyPhone ? `tel:${companyPhone}` : ""}
          className={cn(styles.phone, !companyPhone && "disabled")}
        >
          <span className={styles.phoneIcon}>
            <LandlineIcon />
          </span>
          <span className={styles.phoneText}>
            <span>{formatPhone(companyPhone)}</span>
          </span>
        </a>
      )}
      <Button
        isLink
        to={`${connectPath}?therapistId=${currentProfile?.id}`}
        className={cn(styles.button, styles.premium)}
      >
        <ChatIcon />
        Connect now
      </Button>
      <TherapistMapList />
    </aside>
  );
};
