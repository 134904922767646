export const formatPhone = (phone?: string) => {
  if (!phone) {
    return "Not provided";
  }

  if (phone.includes("+")) {
    // +17169706499
    return phone
      .replace(/\s/g, "")
      .replace(/\+(\d{1})(\d{3})(\d{3})(\d*)/, "+$1 ($2) $3-$4");
  }

  return phone.replace(/\D/g, "").replace(/(\d{3})(\d{3})(\d*)/, "($1) $2-$3");
};
